import React from 'react';
import { graphql } from 'gatsby';
import Navbar from '../common/components/Navbar';
import {
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { theme } from '../ui';
import Markdown from 'markdown-to-jsx';
import Footer from '../common/components/Footer';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const options = {
    overrides: {
      h1: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'h4',
        },
      },
      h2: {
        component: Typography,
        props: { gutterBottom: true, variant: 'h6' },
      },
      h3: {
        component: Typography,
        props: { gutterBottom: true, variant: 'subtitle1' },
      },
      h4: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'caption',
          paragraph: true,
        },
      },
      p: {
        component: Typography,
        props: { paragraph: true },
      },
      li: {
        component: (props) => (
          <Box component="li" sx={{ mt: 1 }}>
            <Typography component="span" {...props} />
          </Box>
        ),
      },
    },
  };

  const { markdownRemark } = data;
  const { frontmatter, html, rawMarkdownBody } = markdownRemark;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Navbar />
      <div>
        <div>
          <Container>
            <h1>{frontmatter.title}</h1>
            <Markdown options={options}>{rawMarkdownBody}</Markdown>
          </Container>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  );
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
      rawMarkdownBody
    }
  }
`;
